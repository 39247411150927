//main_colors
$black: #000000;
$lightGrey: #efefef;
$Grey: #585858;
$blue: #3B75B5;
$red: #ed1c24;
$green: #47c665;
$white: #ffffff;
//ticket_status
$pending: #00a1ed;
$priced: #36898f;
$paid: #d1660e;
$partial_paid: #d1660ead;
$active: #47c665;
$done: #707070;
$approved: #098b40;
$denied: #ed1c24;
//secondery_colors
$darkGreen: #71b54e;
$yellow: #e5e500;
$gray9: #999;
$gray8: #888;
$gray3: #333;
$gray4: #444;
$gray5: #555;
$gray7: #777;
$grayB: #bbb;
$grayD: #ddd;
$grayC: #ccc;
$grayAC: #acacac;
$gray47: #474747;
$grayA7: #a7a7a7;
$gray6A: #6a6a6a;
$whiteE: #eee;
$whiteDA: #dadada;
$whiteFC: #fcfcfc;
$whiteD9: #d9d9d9;
$whiteE4: #e4e4e4;
$orange: #ff5100;
$purple: #734a99;
//techslider
$gold: #d6af36;
$silver: #a7a7ad;
$bronze: #cd7f32;
//system-colors
$visited: #006ea1;
$gray_2: #6d6f72;
$blue_2: #3B75B5;
$blue_3: #40a9ff;
$white_2: #e6f7ff;
$white_3: #ede9ee;
$red_2: #ff4d4f;
$garbage: #7a86a1;

// Direction

$direction: "rtl";
