.header {
  display: flex;
  padding: 0 10px 20px;
  align-items: center;
  justify-content: space-between;
  overflow-x: hidden;
  @media (max-width: 992px) {
    overflow-x: visible;
  }
  div.brand {
    @media (max-width: 992px) {
      order: 2;
    }
    a {
      .logo {
        width: 80px;
        @media (max-width: 992px) {
          width: 70px;
        }
      }
    }
  }
  .header-menu {
    display: flex;
    position: relative;
    border: none;
    @media (max-width: 992px) {
      display: none;
    }
  }
  .ant-menu {
    background-color: transparent;
    color: $grayAC !important;
    border-inline: none !important;
    gap: 1rem;
    .ant-menu-item {
      color: $grayAC !important;
      background-color: transparent;
      overflow: visible;
      &:hover {
        background-color: transparent !important;
        color: rgba($blue_2, 0.7) !important;
      }
      &::after {
        border: none !important;
      }
      .ant-menu-title-content {
        overflow: visible;
        padding-bottom: 8px;
        .notificationNum {
          width: 20px;
          height: 20px;
          font-size: 10px;
          border-radius: 50%;
          background-color: $orange;
          position: absolute;
          color: $white;
          display: flex;
          align-items: center;
          justify-content: center;
          top: 15%;
          left: -5px;
          transform: translate(-50%, -50%);
          animation: 2s scale infinite both;
          box-shadow: 0px 0px 20px rgba($color: $black, $alpha: 0.05);
        }
      }
    }
  }
  .Link {
    li {
      padding-left: 0;
      padding-right: 16px !important;
    }
  }
  .LinkActive {
    .ant-menu-item {
      color: $blue_2 !important;
      &::after {
        background-color: rgba($color: $blue, $alpha: 0.9) !important;
        border-radius: 10px;
        bottom: 3px;
        content: "";
        height: 5px;
        inset-block: unset;
        left: -7px;
        opacity: 1;
        position: absolute;
        transform: none;
        width: 100%;
        top: 30px;
      }
    }
  }
  .ant-input-wrapper {
    background-color: $white;
    border-radius: 30px;
    box-shadow: 0 0 6px rgba($color: $black, $alpha: 0.06);
    padding: 10px;
    overflow: hidden;
    input {
      border: none !important;
      box-shadow: none !important;
      &::placeholder {
        font-family: "Light";
      }
    }
    .ant-input-group-addon {
      box-shadow: none !important;
      button {
        border: none !important;
        box-shadow: none !important;
      }
    }
  }
  .adminInfo {
    border-radius: 15px;
    padding: 10px 30px;
    background-color: $blue_2;
    display: flex;
    gap: 1rem;
    align-items: center;
    cursor: pointer;
    @media (max-width: 992px) {
      padding: 10px 10px;
    }
    img {
      object-fit: cover;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: $whiteE;
      @media (max-width: 992px) {
        margin-left: 10px;
        width: 40px;
        height: 40px;
      }
    }
    .cardText {
      font-family: "Light";

      .title {
        color: $white;
        font-size: 16px;
        font-family: "Light";
        line-height: 20px;
        @media (max-width: 992px) {
          font-size: 14px;
        }
      }
      .des {
        color: rgba($color: $white, $alpha: 0.58);
        font-family: "Light";
        @media (max-width: 992px) {
        }
      }
    }
  }
  div {
    @media (max-width: 992px) {
      order: 3;
    }
    .notificationBtn {
      position: relative;
      @media (max-width: 992px) {
        // margin-left: 20px;
        margin-right: 0;
      }
      .notificationNum {
        width: 20px;
        height: 20px;
        font-size: 10px;
        border-radius: 50%;
        background-color: $orange;
        position: absolute;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        transform: translate(-50%, -50%);
        animation: 2s scale infinite both;
        box-shadow: 0px 0px 20px rgba($color: $black, $alpha: 0.05);
      }
      .anticon-bell {
        width: 25px;
        height: 25px;
        color: $grayAC !important;
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .whatsnew {
    position: absolute;
    top: -15px;
    left: 95%;
    direction: ltr;
    overflow: hidden;
    width: 8em;
    z-index: 2;
    animation-name: wave;
    animation-duration: 4s;
    animation-delay: 8s;
    animation-iteration-count: infinite;
    .text {
      position: absolute;
      white-space: nowrap;
      padding: 0 5px;
      z-index: -3;
      transform: translate(-110%);
      animation-name: text;
      animation-duration: 8s;
    }
  }
  @keyframes wave {
    0% {
      scale: 1;
    }
    50% {
      scale: 1.1;
    }
    100% {
      scale: 1;
    }
  }
  @keyframes text {
    0% {
      transform: translate(-110%);
    }
    25% {
      transform: translate(0%);
    }
    75% {
      transform: translate(0%);
    }
    100% {
      transform: translate(-110%);
    }
  }
}

button.ant-switch {
  * {
    direction: ltr;
  }
}

.ant-popover {
  .ant-popover-content {
    width: 25vw !important;
    @media (max-width: 992px) {
      width: 90vw !important;
    }
    .ant-popover-inner {
      border-radius: 25px;
      .ant-popover-title {
        text-align: center;
        font-size: 18px;
        padding-block: 10px;
        font-family: "Light";
      }
      .notificationCon {
        overflow-y: scroll;
        height: 40vh;
        padding: 10px;

        &::-webkit-scrollbar {
          width: 6px;
          height: 3px;
        }

        &::-webkit-scrollbar-track {
          background-color: $white;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $whiteE;
          border-radius: 20px;
        }

        .notificationBody {
          position: relative;
          border-bottom: 1px solid $whiteE;
          border-radius: 5px;
          &.active {
            background-color: $lightGrey;
            border-bottom: 1px solid $grayD;
          }
          .notificationCard {
            padding: 10px;
            position: relative;
            .title {
              font-size: 16px;
              margin-block-end: 3px;
              color: $black;
              padding-inline: 16% 19%;
            }
            .des {
              font-family: "Light";
              font-size: 13px;
              color: $gray9;
            }
            .time {
              position: absolute;
              top: 10px;

              color: $gray9 !important;
              font-size: 12px;
            }
          }
          .read {
            direction: rtl;
            bottom: 5px;
            position: absolute;
            padding: 0;
            display: flex;
            align-items: center;
            transition-duration: 0.5s;
            line-height: 1.2rem;
            .anticon {
              transition-duration: 0.5s;
              // background-color: #1677ff;
            }
            .title {
              transition-duration: 0.5s;
              border-radius: 20px 0 0 20px;
              font-size: 12px;
              width: 0;
              overflow: hidden;
              margin: 0;
              background-color: #1677ff;
              color: $white;
            }
            &:hover {
              .anticon {
                background-color: #69b1ff;
                color: $white;
                border-radius: 0 20px 20px 0;
              }
              .title {
                width: 5.2rem;
                background-color: #69b1ff;
              }
            }
          }
        }
      }
    }
  }
}

.ant-spin-blur::after {
  opacity: 0 !important;
}

.anticon-menu {
  width: 25px;
  height: 25px;
  color: $grayAC !important;
  order: 1;
  svg {
    width: 100%;
    height: 100%;
  }
}

.ant-drawer-body {
  .adminInfo {
    border-radius: 30px;
    padding: 10px 30px;
    // background-color: $blue;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    margin-bottom: 30px;
    @media (max-width: 992px) {
      padding: 10px 10px;
    }
    img {
      object-fit: cover;
      width: 150px;
      height: 150px;
      border-radius: 50%;
      margin: 20px;
      background-color: $whiteE;
    }
    .cardText {
      font-family: "Light";
      text-align: center;
      border-radius: 10px;
      padding: 10px 30px;
      background-color: $blue;
      .title {
        color: $white;
        font-size: 16px;
        font-family: "Light";
        line-height: 20px;
        @media (max-width: 992px) {
          font-size: 14px;
        }
      }
      .des {
        color: rgba($color: $white, $alpha: 0.58);
        font-family: "Light";
        @media (max-width: 992px) {
        }
      }
    }
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent;
  }
  .ant-menu-item {
    color: $gray5;
    background-color: rgba($color: $gray5, $alpha: 0.1);
    &.redbg {
      background-color: rgba($color: $red, $alpha: 0.1) !important;
    }
  }
  .LinkActive {
    .ant-menu-item {
      color: $blue !important;
      background-color: $white_2 !important;
    }
  }
  .header-menu {
    text-align: center;
    margin-bottom: 30px;
  }
  ul {
    // border: 0px;
    border-inline-end: none !important;
  }
}

.newsModal {
  .ant-modal-content {
    .ant-modal-body {
      border-radius: 5px 20px 20px 5px;
      margin: 0 20px;
      background-color: rgba($color: $blue, $alpha: 0.09);
    }
  }
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
    height: 3px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $whiteDA;
    border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba($color: $blue, $alpha: 0.8);
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $blue;
  }
}
